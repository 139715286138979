import PropTypes from 'prop-types';
import { memo } from 'react';

import placeholder from 'lib/placeholder';

import LINKS from 'constants/links';

const SitemapGroupList = ({ categories = [] }) => (
  <div className="hidden">
    <a href={LINKS.SITEMAP}>{placeholder('actions.seeAllCategories')}</a>
    {categories.map(
      ({ categoryName, categoryUrl, subcategories = [] }, index) => (
        <div key={`${categoryName}-${index}`}>
          <a href={categoryUrl}>{categoryName}</a>
          {subcategories.length > 0 && (
            <div>
              {subcategories.map(({ subcategoryName, subcategoryUrl }) => (
                <a key={subcategoryName} href={subcategoryUrl}>
                  {subcategoryName}
                </a>
              ))}
            </div>
          )}
        </div>
      )
    )}
  </div>
);

SitemapGroupList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number,
      categoryName: PropTypes.string.isRequired,
      categoryUrl: PropTypes.string.isRequired,
      subcategories: PropTypes.array.isRequired,
    }).isRequired
  ),
};

export default memo(SitemapGroupList);
