import { useQuery } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import Badge from 'shopper/components/Badge';
import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import { isInDateRange } from 'lib/date';
import { sendEvent } from 'lib/gtag';
import { getFromLocalStorage, setLocalStorage } from 'lib/localStorage';
import placeholder from 'lib/placeholder';
import { landingQueryKeys } from 'lib/queryKeys';

import { getLandingsList } from 'services/landing';

import CategoriesDropdownButton from './CategoriesDropdownButton';
import StoresDropdownButton from './StoresDropdownButton';

import LINKS from 'constants/links';
import LOCAL_STORAGE from 'constants/localStorage';

const FeaturedLandingLink = dynamic(() => import('./FeaturedLandingLink'), {
  ssr: true,
});

const MAIN_BUTTONS_LIST = [
  {
    href: LINKS.BLOG,
    text: placeholder('actions.seeBlog'),
    gaEventAction: 'topbar_blog_tab',
    testSelectorAttr: 'blog-tab',
    icon: <Icon name="article" />,
  },
  {
    href: LINKS.COUPONS,
    text: placeholder('actions.seeCoupons'),
    gaEventAction: 'topbar_coupon_tab',
    testSelectorAttr: 'coupons-tab',
    icon: <Icon name="discount-filled" />,
  },
  {
    href: LINKS.FORUM,
    text: placeholder('actions.seeForum'),
    gaEventAction: 'topbar_forum_tab',
    testSelectorAttr: 'forum-tab',
    icon: <Icon name="messages" />,
  },
  isInDateRange('2024-12-10', '2024-12-20') && {
    href: LINKS.CHRISTMAS_LANDING,
    text: placeholder('actions.seeChristmasLanding'),
    gaEventAction: 'christmas_landing_tab',
    testSelectorAttr: 'christmas-tab',
    icon: null,
  },
];

const TopbarBottomDefaultNav = ({ gaEventCategory }) => {
  const [christmasLandingHasClicked, setChristmasLandingHasClicked] =
    useState(false);
  const [casaeVideoHasClicked, setcasaeVideoHasClicked] = useState(false);

  useEffect(() => {
    const christmasLandingIsClicked = getFromLocalStorage(
      LOCAL_STORAGE.IS_CHRISTMAS_LANDING_BUTTON_ALREADY_CLICKED
    );
    if (christmasLandingIsClicked) {
      setChristmasLandingHasClicked(true);
    }

    const casaeVideoIsClicked = getFromLocalStorage(
      LOCAL_STORAGE.IS_CASAEVIDEO_BUTTON_ALREADY_CLICKED
    );
    if (casaeVideoIsClicked) {
      setcasaeVideoHasClicked(true);
    }
  }, []);

  const handleClick = (button) => {
    if (button === 'christmas-tab') {
      setChristmasLandingHasClicked(true);
      setLocalStorage(
        LOCAL_STORAGE.IS_CHRISTMAS_LANDING_BUTTON_ALREADY_CLICKED,
        'true'
      );
      return;
    }

    if (button === 'casaevideo-tab') {
      setcasaeVideoHasClicked(true);
      setLocalStorage(
        LOCAL_STORAGE.IS_CASAEVIDEO_BUTTON_ALREADY_CLICKED,
        'true'
      );
      return;
    }
  };

  const { data: featuredLanding } = useQuery({
    queryKey: landingQueryKeys.featured(),
    queryFn: ({ signal }) => getLandingsList({ featured: true }, { signal }),
    enabled: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <CategoriesDropdownButton gaEventCategory={gaEventCategory} />
      <StoresDropdownButton gaEventCategory={gaEventCategory} />
      {MAIN_BUTTONS_LIST.filter(Boolean).map(
        ({ gaEventAction, href, text, testSelectorAttr, icon }) => {
          const showBadge =
            testSelectorAttr === 'christmas-tab' ||
            testSelectorAttr === 'casaevideo-tab';

          const isClicked =
            (testSelectorAttr === 'christmas-tab' &&
              christmasLandingHasClicked) ||
            (testSelectorAttr === 'casaevideo-tab' && casaeVideoHasClicked);
          return (
            <NextLink key={href} href={href} prefetch={false} passHref>
              <Button
                as="a"
                className={twJoin(
                  'relative mr-4',
                  testSelectorAttr === 'casaevideo-tab' && 'p-1 [&>svg]:size-6'
                )}
                data-test-selector={testSelectorAttr}
                iconLeft={icon}
                size="size4"
                type="neutral-ghost"
                onClick={() => {
                  showBadge && handleClick(testSelectorAttr);
                  sendEvent({
                    action: gaEventAction,
                    category: gaEventCategory,
                  });
                }}
              >
                {text}
                {showBadge && !isClicked && (
                  <Badge
                    className="absolute right-0 top-1"
                    shape="dot"
                    type="highlight"
                  />
                )}
              </Button>
            </NextLink>
          );
        }
      )}
      {featuredLanding && (
        <FeaturedLandingLink
          gaEventCategory={gaEventCategory}
          landingId={featuredLanding.landingId}
          landingName={featuredLanding.landingName}
          landingSlug={featuredLanding.landingSlug}
          landingType={featuredLanding.landingType}
        />
      )}
    </>
  );
};

TopbarBottomDefaultNav.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
};

export default TopbarBottomDefaultNav;
